
import { defineComponent, ref } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'

export default defineComponent({
  name: 'DropdownPublications',
  setup(props, ctx) {
    const isVisible = ref(false)
    const selected = ref(false)
    const chromeData = ref()

    chrome().then(r => {
      chromeData.value = r.data
    })

    const showAvatarModal = () => {
      isVisible.value = true
    }

    const hideAvatarModal = () => {
      isVisible.value = false
    }

    const selectPublication = (title: any) => {
      selected.value = title
      ctx.emit('update:publicationSelected', title.title)

      isVisible.value = false
    }

    return {
      chromeData,
      hideAvatarModal,
      isVisible,
      showAvatarModal,
      selectPublication,
      selected,
    }
  },
})
