
import { defineComponent, ref, reactive } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { order } from '@/guides/endpoints/news'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import FormInput from '@/components/FormInput.vue'
import Availability from '@/components/Availability.vue'
import DropdownPublications from '@/components/DropdownPublications.vue'

export default defineComponent({
  components: {
    Alert,
    BackBtn,
    Btn,
    Feedback,
    FormInput,
    Availability,
    DropdownPublications,
  },
  setup() {
    const chromeData = ref()
    const showAlert = ref(false)
    const uploadPhotos = ref()
    const publicationsList = ref(1)

    const publication = ref([])

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    chrome().then(r => {
      chromeData.value = r.data
    })

    const form = reactive({
      mode: '',
      date: {
        date: '',
        time: '',
      },
    })

    const addPublicationItem = () => {
      publicationsList.value += 1
    }

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.append('publications[]', publication.value as any)

      data.set('date', form.date.date)

      order(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      showAlert,
      uploadPhotos,
      chromeData,
      addPublicationItem,
      publicationsList,
      publication,
    }
  },
})
