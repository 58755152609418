<template>
  <div class="dropdown-publications" v-if="chromeData">
    <div @click.prevent="showAvatarModal" class="dropdown-publications__choice">
      <div class="dropdown-publications__choice__selected" v-if="selected">
        <div class="icon">
          <SvgSprite symbol="icons-check" size="14 11" />
        </div>
        <div class="label">
          <span>{{ selected.title }}</span>
          <span>{{ selected.price }}</span>
        </div>
      </div>
      <div v-else>Choisir une publication</div>
      <SvgSprite class="card__arrow" symbol="ui-arrow-right" size="16" />
    </div>
    <ul class="dropdown-publications__list" v-if="isVisible">
      <li
        class="dropdown-publications__list__collection"
        v-for="collection in chromeData.lists.collections"
        :key="collection.title"
      >
        <div class="collection__title">{{ collection.title }}</div>

        <ul>
          <li
            class="dropdown-publications__list__publication"
            v-for="publication in collection.publications"
            @click.prevent="selectPublication(publication)"
            :key="publication.title"
          >
            <span class="publication__title">{{ publication.title }}</span>
            <span class="publication__price">{{ publication.price }}</span>
          </li>
        </ul>
      </li>
    </ul>

    <div
      class="outside"
      v-show="isVisible"
      @click.prevent="hideAvatarModal"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'

export default defineComponent({
  name: 'DropdownPublications',
  setup(props, ctx) {
    const isVisible = ref(false)
    const selected = ref(false)
    const chromeData = ref()

    chrome().then(r => {
      chromeData.value = r.data
    })

    const showAvatarModal = () => {
      isVisible.value = true
    }

    const hideAvatarModal = () => {
      isVisible.value = false
    }

    const selectPublication = (title: any) => {
      selected.value = title
      ctx.emit('update:publicationSelected', title.title)

      isVisible.value = false
    }

    return {
      chromeData,
      hideAvatarModal,
      isVisible,
      showAvatarModal,
      selectPublication,
      selected,
    }
  },
})
</script>

<style scoped lang="scss">
.dropdown-publications {
  position: relative;
  width: 100%;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      color: $regal-blue;
      border-bottom: 1px solid $rock-blue;

      &:last-child {
        border-bottom: 0;
      }

      li {
        padding: 1.3rem 2rem;
        border-top: 1px solid $rock-blue;
        border-bottom: 0;
      }
    }
  }

  > ul {
    overflow: auto;
  }
}

.dropdown-publications__choice {
  @include fira;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 1rem;
  color: $rock-blue;
  font-size: 1.4rem;
  border-right: 1px solid $rock-blue;
  cursor: pointer;
}

.dropdown-publications__list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  max-height: 30rem;

  background: white;
  box-shadow: 0px 4px 24px rgba(102, 140, 175, 0.2);
}

.dropdown-publications__list__collection {
  div {
    padding: 1.3rem 2rem 0.3rem;
    font-weight: 700;
    opacity: 0.6;
  }
}

.dropdown-publications__list__publication {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: rgba($pale-blue, 0.3);
  }
}

.collection__title {
  margin-top: 2rem;
  font-size: 1rem;
  text-transform: uppercase;
}

.publication__price {
  font-size: 1.2rem;
  color: $rock-blue;
}

.outside {
  @include get-all-space;
  position: fixed;
  z-index: 12;
  background: transparent;
  cursor: pointer;
}

.card__arrow {
  width: 1.6rem;
  height: 1.6rem;
  pointer-events: none;
  stroke: $tangerine;
  stroke-width: 2;
  transform: rotate(90deg);
}

.dropdown-publications__choice__selected {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 2rem;
  color: $regal-blue;

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .icon {
    margin-right: 0.8rem;

    width: 2.4rem;
    height: 2.4rem;
    background-color: $la-rioja;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
