<template>
  <div class="order-form fill-height" v-if="chromeData">
    <Alert :show="showAlert && !!showError?.message">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : {{ showError?.message }}</span>
    </Alert>
    <BackBtn />
    <div class="wrapper wrapper--small p-l">
      <h3 class="mb-m">Commander des publications</h3>

      <form ref="formel" @submit.prevent="onSubmit" method="POST">
        <div class="infos mb-s">
          <div class="mb-s">
            <h5 class="mb-xxs">Prénom</h5>
            <FormInput name="firstname" />
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Nom</h5>
            <FormInput name="lastname" />
          </div>
        </div>

        <div class="mb-s">
          <h5 class="mb-xxs">Adresse email</h5>
          <FormInput name="email" />
        </div>

        <!-- Publications -->
        <div class="mb-s">
          <h5 class="mb-xxs">Nos publications</h5>

          <div
            class="order-form__publication"
            v-for="(item, index) in publicationsList"
            :key="item"
          >
            <DropdownPublications
              v-model:publicationSelected="publication[index]"
            ></DropdownPublications>

            <div class="order-form__quantities">
              <FormInput placeholder="0" name="quantities[]" />
            </div>
          </div>

          <a class="add" @click.prevent="addPublicationItem">
            <SvgSprite symbol="icons-add" size="16" />
            <span>Ajouter une publication</span>
          </a>
        </div>

        <div class="mb-s">
          <h5 class="mb-xxs">Remarque</h5>
          <FormInput name="remarque" />
        </div>

        <!-- Mode -->
        <div class="mode mb-s">
          <div class="mb-s">
            <label class="input-radio mb-xs">
              <input
                v-model="form.mode"
                name="mode"
                value="carrick"
                type="radio"
                required
              />
              <span
                >Je souhaite éviter les frais de port et récupérer ma commande
                au Carrick, Rue Paul-Emile Janson 35 à Ixelles. Les locaux sont
                accessibles du lundi au vendredi de 9h à 17h.
              </span>
            </label>

            <label class="input-radio mb-xs">
              <input
                v-model="form.mode"
                name="mode"
                value="home"
                type="radio"
                required
              />
              <span
                >Je souhaite que ma commande me soit envoyée par Bpost. Les
                frais de port sont à ma charge. Ils se situent entre 2,85 € et
                4,75 € pour une enveloppe et entre 6,50 € et 7,80 € pour un
                colis.
                <small
                  >Les frais de port feront l'objet d'une facture qui te sera
                  envoyée a posteriori, par email. Ton colis sera livré au point
                  poste le plus proche de l'adresse que tu communiqueras
                  ci-dessous. S'il s'agit d'une enveloppe, celle-ci pourra être
                  envoyée directement à cette adresse.</small
                ></span
              >
            </label>
          </div>
        </div>

        <div v-if="form.mode === 'carrick'">
          <h5>
            Je prends rendez-vous pour retirer ma commande au Carrick
          </h5>

          <Availability
            v-model:date="form.date.date"
            v-model:time="form.date.time"
          />
        </div>

        <div v-if="form.mode === 'home'">
          <div class="infos mb-s">
            <div class="mb-s">
              <h5 class="mb-xxs">Nom et prénom du destinataire</h5>
              <FormInput name="name" />
            </div>

            <div class="mb-s">
              <h5 class="mb-xxs">Rue et numéro</h5>
              <FormInput name="street" />
            </div>
          </div>

          <div class="infos mb-s">
            <div class="mb-s">
              <h5 class="mb-xxs">Code postal</h5>
              <FormInput name="zipcode" />
            </div>

            <div class="mb-s">
              <h5 class="mb-xxs">Localité</h5>
              <FormInput name="city" />
            </div>
          </div>
        </div>

        <div class="error m-m" v-if="showError?.message">
          <h4>{{ showError.message }}</h4>

          <ul>
            <li v-for="(val, key) in showError?.errors" :key="key">
              {{ val.join(',') }}
            </li>
          </ul>
        </div>

        <Btn class="send mt-s">Je commande</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Commande en cours d'envoi...</h4>
        <p>Ta commande est en cours d'envoi...</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Merci !</h4>
        <p>
          Ta commande a bien été envoyée.
        </p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { order } from '@/guides/endpoints/news'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import FormInput from '@/components/FormInput.vue'
import Availability from '@/components/Availability.vue'
import DropdownPublications from '@/components/DropdownPublications.vue'

export default defineComponent({
  components: {
    Alert,
    BackBtn,
    Btn,
    Feedback,
    FormInput,
    Availability,
    DropdownPublications,
  },
  setup() {
    const chromeData = ref()
    const showAlert = ref(false)
    const uploadPhotos = ref()
    const publicationsList = ref(1)

    const publication = ref([])

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    chrome().then(r => {
      chromeData.value = r.data
    })

    const form = reactive({
      mode: '',
      date: {
        date: '',
        time: '',
      },
    })

    const addPublicationItem = () => {
      publicationsList.value += 1
    }

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.append('publications[]', publication.value as any)

      data.set('date', form.date.date)

      order(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      showAlert,
      uploadPhotos,
      chromeData,
      addPublicationItem,
      publicationsList,
      publication,
    }
  },
})
</script>

<style lang="scss" scoped>
.order-form {
  @include form-base;

  background: $c-white;

  ::v-deep(h5) {
    margin-bottom: 0.6rem;
  }
}

@include mq(l) {
  .infos {
    display: flex;
    justify-content: space-between;

    > * {
      flex-basis: calc(50% - 1rem);
      margin-bottom: 0;
    }
  }
}

.order-form__publication {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  background-color: $athens-gray;
}

.order-form__quantities {
  @include fira;

  width: 4.8rem;
  color: $regal-blue;

  ::v-deep(input) {
    text-align: center;
  }
}

.input-radio span {
  small {
    padding-top: 10px;
    display: block;
    text-indent: 0;
    opacity: 0.5;
  }
}

.availability {
  ::v-deep(.number),
  ::v-deep(.time) {
    display: none;
  }
}
</style>
